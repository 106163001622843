import { BellOutlined } from "@ant-design/icons";
import { TaskNotificationIcon } from "@dewo/app/components/icons/TaskNotification";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { useRunning } from "@dewo/app/util/hooks";
import { Button, message, Row, Typography } from "antd";
import React, { FC, useCallback, useMemo } from "react";
import { useUpdateUser } from "../../user/hooks";
import { UserPromptStepComponentProps } from "../types";

export const EnableOpenTaskNotifications: FC<UserPromptStepComponentProps> = ({
  onNext,
}) => {
  const handleNext = useCallback(() => onNext(), [onNext]);
  const { user } = useAuthContext();
  const latestFollowedOrg = useMemo(
    () =>
      user?.node.children
        .slice()
        .reverse()
        .map((e) => e.node.organization)
        .find((o) => !!o),
    [user?.node.children]
  );

  const updateUser = useUpdateUser();
  const [handleEnable, loading] = useRunning(
    useCallback(async () => {
      await updateUser({ openTasksNotifications: true });
      message.success("Notifications enabled!");
      onNext();
    }, [onNext, updateUser])
  );

  return (
    <>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Be the first to know about new tasks
      </Typography.Title>
      <Typography.Paragraph
        type="secondary"
        style={{ textAlign: "center", fontSize: "130%" }}
      >
        We'll send you a Discord DM when {latestFollowedOrg?.name} has new open
        tasks!
      </Typography.Paragraph>

      <Row align="middle" justify="center" style={{ flex: 1, fontSize: 100 }}>
        <TaskNotificationIcon style={{ width: "60%" }} />
      </Row>

      <Button
        loading={loading}
        type="primary"
        size="large"
        name="Open task notifications: Enable"
        onClick={handleEnable}
        icon={<BellOutlined />}
      >
        Enable Notifications
      </Button>

      <Button
        type="text"
        size="small"
        style={{ marginTop: 8 }}
        className="text-secondary"
        name="Open task notifications: Close"
        onClick={handleNext}
      >
        Close
      </Button>
    </>
  );
};
