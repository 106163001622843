import { MinusCircleOutlined, StarOutlined } from "@ant-design/icons";
import { useOnboardingTip } from "@dewo/app/components/OnboardingAlert";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { stopPropagation } from "@dewo/app/util/eatClick";
import { useRunning } from "@dewo/app/util/hooks";
import { Button } from "antd";
import useModal from "antd/lib/modal/useModal";
import React, { FC, useMemo, MouseEvent, useCallback } from "react";
import { LoginButton } from "../../auth/buttons/LoginButton";
import { EnableOpenTaskNotifications } from "../../prompts/notifications/EnableOpenTaskNotifications";
import { useFollowGraphNode, useUnfollowGraphNode } from "../hooks";

interface Props {
  nodeId: string;
  showUnfollow?: boolean;
}

export const FollowGraphNodeButton: FC<Props> = ({
  nodeId,
  showUnfollow = true,
}) => {
  const { user } = useAuthContext();
  const following = useMemo(
    () => !!user?.node.children.some((edge) => edge.node.id === nodeId),
    [user?.node.children, nodeId]
  );

  const follow = useFollowGraphNode();
  const unfollow = useUnfollowGraphNode();

  const notificationPrompt = useOnboardingTip(
    `OpenTaskNotificationsAfterFollowing`
  );

  const [modal, contextHolder] = useModal();

  const [toggleFollowing, toggling] = useRunning(
    useCallback(
      async (event: MouseEvent) => {
        stopPropagation(event);
        if (following) {
          await unfollow(nodeId);
        } else {
          await follow(nodeId);

          if (!user?.openTasksNotifications && !notificationPrompt.hidden) {
            const rendered = modal.info({});
            rendered.update({
              icon: null,
              okButtonProps: { style: { display: "none" } },
              content: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <EnableOpenTaskNotifications
                    active
                    onNext={async () => {
                      notificationPrompt.onClose();
                      rendered.destroy();
                    }}
                    onCancel={Promise.resolve}
                  />
                </div>
              ),
            });
          }
        }
      },
      [
        following,
        nodeId,
        follow,
        unfollow,
        user?.openTasksNotifications,
        notificationPrompt,
        modal,
      ]
    )
  );

  if (!user) {
    return (
      <LoginButton type="primary" icon={<StarOutlined />} children="Follow" />
    );
  }

  if (following && !showUnfollow) return null;

  const button = following ? (
    <Button
      loading={toggling}
      icon={<MinusCircleOutlined />}
      onClick={toggleFollowing}
      children="Unfollow"
    />
  ) : (
    <Button
      loading={toggling}
      type="primary"
      icon={<StarOutlined />}
      onClick={toggleFollowing}
      children="Follow"
    />
  );
  return (
    <>
      {button}
      {contextHolder}
    </>
  );
};
